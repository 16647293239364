import * as Sentry from "@sentry/svelte";
import { user } from "../stores/index";
import getFile from "./xml";
const config = getFile("/config.json");

function init() {
  Sentry.init({
    environment: config.NODE_ENV,
    dsn: config.SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration()
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

  // trace user
  user.subscribe((u) => {
    if (u === null) {
      Sentry.setUser(null);
    } else {
      Sentry.setUser({
        id: u.data?.id,
        policies: u.policies
      });
    }
  });
}

export default {
  init
};
